import React from 'react';
import Layout from '../components/layout/Layout';

const Index = () => (
  <div className="m-4">
   <h1 className="text-2xl">Page not found...</h1>
  </div>
);

// import React, { useState } from 'react';
// import { Link } from 'gatsby'
// import Button from '../components/Button';
// import ContactFormHooks from '../components/ContactFormHooks';
// import Layout from '../components/layout/Layout';
// import BuildingImage from '../assets/building.jpg'
// import BuildingImageExt from '../assets/building_ext.svg'

// import '../../css/custom.css';


// const Building = () => (
//   <div className="">
//      <img src={BuildingImage} alt="building" />
//   </div>
// )
// const BuildingExt = () => (
//   <div className="">
//      <img src={BuildingImageExt} alt="building" />
//   </div>
// )

// const Index = () => {

 

//   return (
//   <Layout>
//     <section id="home" className="pt-20 md:pt-10">
//       <div className="container mx-auto px-8 lg:flex">
//         <div className="text-center lg:text-left lg:w-1/2 md:pl-4">
//           <h1 className="mate 2xl:text-3xl pl-2 xl:text-3xl  lg:text-3xl font-semibold leading-none md:text-3xl sm:text-3xl">
//           A Full Suite of High-Touch Services for Private Families
//           </h1>
//           <p className="2xl:text-xl 2xl:px-2 xl:px-2 lg:px-2 xl:text-xl lg:text-lg md:text-xl sm:text-lg sm:px-8 mt-6 font-light">
//           Exigent722 is being established to become Israel’s leading multi-family office catering to the
//             full breadth of needs for the returning Oleh Community.
//           </p>
//           <p className="2xl:text-xl 2xl:px-2 xl:px-2 lg:px-2 xl:text-xl lg:text-lg md:text-xl sm:text-lg sm:px-8 mt-6 font-light">
//           722 BCE marks the year that King Sargon of Assyria invaded Israel and captured the ten northern tribes – leading to the first exile of the Jewish People from their ancestral homeland. Our mission is to help undo the exile. 
//           </p>
//           <p className="2xl:text-xl 2xl:px-2 xl:px-2 lg:px-2 xl:text-xl lg:text-lg md:text-xl sm:text-lg sm:px-8 mt-6 font-light">
//           Exigent722 will offer a full suite of high-touch services for private families including Asset Management, Multi-jurisdictional Tax and Estate Planning, Philanthropic Advisory and Due Diligence and Generational Wealth Transfer. In addition, we plan to bring best of class service providers from our own ecosystem to assist our clients with pre-and-post Aliyah Concierge, Home Purchase, Mortgage, Insurance, Property Management and Private Banking. 
//           </p>
//           <p className="2xl:text-xl 2xl:px-2 xl:px-2 lg:px-2 xl:text-xl lg:text-lg md:text-xl sm:text-lg sm:px-8 mt-6 font-light">
//           If you are interested in a consultation, please tell us a bit about you and your family:          </p>
//         </div>
//         <div className="lg:w-1/2 lg:pr-4 md:mr-8 md:mt-2">
//           <ContactFormHooks />
//         </div>
//       </div>
//     </section>
   
//     <section id="stats" className="py-20 lg:pt-32">
//       <div className="mx-auto text-center">
//         <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
//           <div className="w-full sm:w-1/3">
//           </div>
//           <div className="w-full sm:w-1/3">
//            {/* <Building /> */}
//            <BuildingExt />
//           </div>
//           <div className="w-full sm:w-1/3">
//           </div>
//         </div>
//       </div>
//     </section>
//     <section id="stats" className="">
//       <div className="container mx-auto text-center">
//         {/* <LabelText className="text-gray-600">Our customers get results</LabelText> */}
//         <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
//           <div className="w-full sm:w-1/3">
//             {/* <StatsBox primaryText="+100%" secondaryText="Stats Information" /> */}
//           </div>
//           <div className="w-full sm:w-1/3">
//           <h3 className="text-2xl font-thin">Exigent Capital Group</h3>
//       <p className="text-xl font-light">
//       5 Wyndham Deedes St.
//       </p>
//       <p className="text-xl font-light mb-6">
//       Jerusalem, Israel 9310633
//       </p>
//       <p className="text-xl font-light">
//       Israel +972 2 500 9450
//       </p>
//       <p className="text-xl font-light">
//       US 646 506 9450
//       </p>
//       <p className="text-xl font-light">
//       <a href="mailto:exigent722@exigentcap.com">exigent722@exigentcap.com</a>
//       </p>
//           </div>
//           <div className="w-full sm:w-1/3">
//           </div>
//         </div>
//       </div>
//     </section>
   
//     <section className="container mx-auto rounded-lg text-center mt-24 pt-12">
//       <h3 className="2xl:text-3xl xl:text-3xl lg:text-3xl sm:text-4xl xs:text-3xl font-semibold size mate">Congratulations on your decision to make Aliyah!</h3>
//       <p className="mt-8 text-xl font-light">
//       If you are interested in a consultation, please contact us:
//       </p>
//       <p className="mt-12">
//         <Button  size="lg"  style={{
//           backgroundColor: '#C41E3A'
//         }}><Link to="/#home">Contact Us Now</Link></Button>
//       </p>
//     </section>
//   </Layout>
//   )
// };

export default Index;
